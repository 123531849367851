<template>
  <div class="video-container">
    <div v-if="isLoadingExpire" class="loading-overlay">
      <div class="loader"></div>
      <p>Түр хүлээнэ үү...</p>
    </div>
    <video v-if="!isLoadingExpire && isSubscribed" ref="videoPlayer" controls class="video-js vjs-default-skin">
      <track v-for="track in episode.subs" :key="track.code" kind="subtitles" :src="track.path" :srclang="track.code"
             :label="track.label" default>
    </video>

    <div v-if="!isLoadingExpire &&  !isSubscribed" class="subscribe-overlay">
      <div class="overlay-content">
        <h2>Та энэхүү контентыг үзэхийн тулд эхлээд эрхээ сунгана уу!</h2>
        <button @click="goToPay">Эрх сунгах</button>
      </div>
    </div>

    <div class="overlay">
      <button class="back-button" :onclick="this.backToPage">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve" fill="#fff"
             height="30px">
                <g><path d="M990,503.4c0,25.9-21,46.9-46.9,46.9H56.9c-25.9,0-46.9-21-46.9-46.9v-4.6c0-25.9,21-46.9,46.9-46.9h886.1c25.9,0,46.9,21,46.9,46.9V503.4z"></path>
                  <path
                      d="M430.9,131.1c18.3,18.3,18.3,48.1,0,66.4L93.1,535.2c-18.3,18.3-48.1,18.3-66.4,0l-2.9-2.9C5.5,514,5.5,484.3,23.9,466l337.7-337.7c18.3-18.3,48.1-18.3,66.4,0L430.9,131.1z"></path>
                  <path
                      d="M430.9,868.9c18.3-18.3,18.3-48.1,0-66.4L93.1,464.8c-18.3-18.3-48.1-18.3-66.4,0l-2.9,2.9C5.5,486,5.5,515.7,23.9,534l337.7,337.7c18.3,18.3,48.1,18.3,66.4,0L430.9,868.9z"></path></g>
              </svg>
      </button>
      <div class="title-info">
        <h2>{{ episode?.atitle ?? '' }}</h2>
        <p class="epTitle">{{ episode?.number ?? '' }} -р анги</p>
      </div>
    </div>
  </div>
</template>

<script>
import videoJs from 'video.js';
import 'video.js/dist/video-js.css';
import axios from "axios";
import config from "@store/_boot/config";
import {mapGetters} from "vuex";


export default {
  props: ["currentEpisode", "backLink", "episode"],

  data() {
    return {
      // videoUrl: 'https://bitdash-a.akamaihd.net/content/sintel/hls/playlist.m3u8',
      videoUrl: null,
      backToPage: this.closePage,
      saveLogTimeOut: 20,
      isSubscribed: false,
      isLoadingExpire: true,
    };
  },
  mounted() {
    console.log('episode ----', this.episode);
    console.log('currentEpisode ----', this.currentEpisode);
    console.log('backLink ----', this.backLink);
    this.checkExpire();

  },
  created() {
  },
  methods: {

    checkExpire() {
      this.isLoadingExpire = true;
      axios.get(`${config.HOST}/api/m/svod/series/check/${this.user.id}`).then(({data}) => {
        if (data > 0) {
          this.isSubscribed = true;
          this.$nextTick(() => {
            if (this.$refs.videoPlayer) {
              this.loadVideo();
            }
          });
        }
      });
      this.isLoadingExpire = false;
    },

    loadVideo() {
      require('@silvermine/videojs-quality-selector')(videoJs);

      const player = videoJs(this.$refs.videoPlayer, {
        autoplay: true,
        fluid: true,
        nativeTextTracks: false,
        preload: "auto",
        with: "100%",
        height: "100%",
        controlBar: {
          children: [
            'playToggle',
            'volumePanel',
            'progressControl',
            'remainingTimeDisplay',
            'SubsCapsButton',
            'qualitySelector',
            'pictureInPictureToggle',
            'fullscreenToggle',
          ],
        },
        sources: [
          {src: this.episode.vid1, type: 'application/x-mpegURL', label: '480p', res: 480, default: true},
          {src: this.episode.vid2, type: 'application/x-mpegURL', label: '720p', res: 720, default: false},
          {src: this.episode.vid3, type: 'application/x-mpegURL', label: '1080p', res: 1080, default: false},
        ]
      }, function () {
      });

      player.on('timeupdate', () => {

        this.logAt20Seconds(player);
      });
      player.ready(() => {
        // player.videoJsResolutionSwitcher();


      })


    },
    logAt20Seconds(player) {
      if (player.currentTime() >= this.saveLogTimeOut) {
        this.saveLogTimeOut = player.currentTime() + 20;
        this.saveLog(player.currentTime(), player.duration());
      }
    },
    closePage() {
      this.$router.push('/theatre/detail/' + this.episode.anime_id);
    },
    goToPay() {
      this.$router.push('/payment');
    },


    saveLog(currentSecond, totalDuration) {
      console.log(currentSecond);
      console.log(totalDuration);
      axios.post(`https://log.btvplus.mn/history/`, {
        "duration": totalDuration,
        "user": 3,
        "time": currentSecond === 0 ? 1 : currentSecond,
        "media": this.episode.anime_id,
        "episode": this.episode.id,
        "m": this.episode.mainCatId,
      }).then(({data}) => {
        console.log(data);
      })
    },
  },
  computed: {
    ...mapGetters(['user']),
  },

};
</script>

<style scoped>
.video-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.video-js {
  width: 100% !important;
  height: 100% !important;
}


.overlay {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  color: white;
  font-family: Arial, sans-serif;
}

.back-button {
  height: 40px;
  width: 40px;
  cursor: pointer;
}

.title-info {
  text-align: right;
  font-size: 20px;
}

.epTitle {
  font-size: 15px;
}

h2, p {
  margin: 0;
}

.subscribe-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
  margin: 5px 20px 5px 20px;
  position-area: center;
}

.subscribe-overlay button {
  border-radius: 10px;
  border: solid white 1px;
  padding: 10px 20px;
  font-size: 18px;
  cursor: pointer;
  margin-top: 10px;
  color: white;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 18px;
}

.loader {
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-top: 5px solid white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>